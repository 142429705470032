import {
  Andromeda,
  Arbitrum,
  Aurora,
  Avalanche,
  BSC,
  Base,
  Celo,
  Cronos,
  Fantom,
  Gnosis,
  Linea,
  Mainnet,
  Moonriver,
  Optimism,
  Polygon,
  Telos,
} from "../config/chains";
import { IChain } from "../contexts/ChainConfigContext";
import {
  TEST_CHAIN_ENABLED,
  TEST_FORKED_CHAIN_ID,
  TEST_CHAIN_ID,
  TEST_CHAIN_RPC_URL,
} from "./constants";

const chainList: IChain[] = [
  // {
  //    chainId: 1,
  //    chainKey: "eth",
  //    name: "Ethereum",
  //    rpcUrl: "https://eth.llamarpc.com",
  //    currency: "ETH",
  //    nativeTokenReserve: 0.01,
  //    weth: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
  //    dexScreenerChainId: "ethereum",
  //    dexToolsChainId: "ether",
  //    image: "ethereum.png",
  //    useDappChain: Mainnet,
  //    explorerUrl: Mainnet.blockExplorerUrl
  //  },
  {
    chainId: 56,
    chainKey: "bsc",
    name: "BSC",
    rpcUrl: "https://bsc-dataseed.bnbchain.org",
    // rpcUrl: "https://bsc-mainnet.nodereal.io/v1/30a48d6ca97f43e9a5d0502e7849a001",
    currency: "BNB",
    nativeTokenReserve: 0.01,
    weth: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    dexScreenerChainId: "bsc",
    dexToolsChainId: "bnb",
    image: "bsc.png",
    useDappChain: BSC,
    explorerUrl: BSC.blockExplorerUrl,
  },
  // {
  //   chainId: 324,
  //   chainKey: "zksync",
  //   name: "Era",
  //   rpcUrl: "https://mainnet.era.zksync.io",
  //   currency: "ETH",
  //   nativeTokenReserve: 0.01,
  //   weth: "0x5aea5775959fbc2557cc8789bc1bf90a239d9a91",
  //   dexScreenerChainId: "zksync",
  //   dexToolsChainId: "zksync",
  //   image: "zksync.png"
  // },
  {
    chainId: 137,
    chainKey: "polygon",
    name: "Polygon",
    // rpcUrl: "https://polygon.llamarpc.com",
    rpcUrl: "https://polygon-bor-rpc.publicnode.com",
    currency: "MATIC",
    nativeTokenReserve: 0.01,
    weth: "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270",
    dexScreenerChainId: "polygon",
    dexToolsChainId: "polygon",
    image: "polygon.png",
    useDappChain: Polygon,
    explorerUrl: Polygon.blockExplorerUrl,
  },
  // {
  //   chainId: 80002,
  //   chainKey: "amoy",
  //   name: "Polygon Amoy",
  //   rpcUrl: "https://rpc-amoy.polygon.technology",
  //   currency: "MATIC",
  //   nativeTokenReserve: 0.01,
  //   weth: "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270",
  //   dexScreenerChainId: "amoy",
  //   dexToolsChainId: "amoy",
  //   image: "polygon.png",
  //   useDappChain: Polygon,
  //   explorerUrl: Polygon.blockExplorerUrl
  // },
  // {
  //   chainId: 8453,
  //   chainKey: "base",
  //   name: "Base",
  //   rpcUrl: "https://mainnet.base.org",
  //   currency: "ETH",
  //   nativeTokenReserve: 0.01,
  //   weth: "0x4200000000000000000000000000000000000006",
  //   dexScreenerChainId: "base",
  //   dexToolsChainId: "base",
  //   image: "base.png",
  //   useDappChain: Base,
  //   explorerUrl: Base.blockExplorerUrl
  // },
  // {
  //   chainId: 59144,
  //   chainKey: "linea",
  //   name: "Linea",
  //   rpcUrl: "https://rpc.sepolia.linea.build",
  //   currency: "ETH",
  //   nativeTokenReserve: 0.01,
  //   weth: "0xe5D7C2a44FfDDf6b295A15c148167daaAf5Cf34f",
  //   dexScreenerChainId: "linea",
  //   dexToolsChainId: "linea",
  //   image: "linea.png",
  //   useDappChain: Linea,
  //   explorerUrl: Linea.blockExplorerUrl
  // },
  {
    chainId: 250,
    chainKey: "fantom",
    name: "Fantom",
    rpcUrl: "https://rpcapi.fantom.network",
    currency: "FTM",
    nativeTokenReserve: 0.01,
    weth: "0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83",
    dexScreenerChainId: "fantom",
    dexToolsChainId: "fantom",
    image: "fantom.png",
    useDappChain: Fantom,
    explorerUrl: Fantom.blockExplorerUrl,
  },
  // {
  //   chainId: 43114,
  //   chainKey: "avax",
  //   name: "Avalanche",
  //    rpcUrl: "https://api.avax.network/ext/bc/C/rpc",
  //    currency: "AVAX",
  //    nativeTokenReserve: 0.01,
  //    weth: "0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7",
  //   dexScreenerChainId: "avalanche",
  //    dexToolsChainId: "avalanche",
  //    image: "avalanche.png",
  //    useDappChain: Avalanche,
  //     explorerUrl: Avalanche.blockExplorerUrl
  //   },
  {
    chainId: 42161,
    chainKey: "arbitrum",
    name: "Arbitrum",
    rpcUrl: "https://arb1.arbitrum.io/rpc",
    currency: "ETH",
    nativeTokenReserve: 0.01,
    weth: "0x82af49447d8a07e3bd95bd0d56f35241523fbab1",
    dexScreenerChainId: "arbitrum",
    dexToolsChainId: "arbitrum",
    image: "arbitrum.png",
    useDappChain: Arbitrum,
    explorerUrl: Arbitrum.blockExplorerUrl,
  },
  {
    chainId: 10,
    chainKey: "optimism",
    name: "Optimism",
    rpcUrl: "https://mainnet.optimism.io",
    currency: "ETH",
    nativeTokenReserve: 0.01,
    weth: "0x4200000000000000000000000000000000000006",
    dexScreenerChainId: "optimism",
    dexToolsChainId: "optimism",
    image: "optimism.png",
    useDappChain: Optimism,
    explorerUrl: Optimism.blockExplorerUrl,
  },
  // {
  //   chainId: 1285,
  //   chainKey: "moonriver",
  //   name: "Moonriver",
  //   rpcUrl: "https://rpc.api.moonriver.moonbeam.network",
  //   currency: "MOVR",
  //   nativeTokenReserve: 0.01,
  //   weth: "0x98878B06940aE243284CA214f92Bb71a2b032B8A",
  //   dexScreenerChainId: "moonriver",
  //   dexToolsChainId: "moonriver",
  //   image: "moonriver.png",
  //   useDappChain: Moonriver,
  //   explorerUrl: Moonriver.blockExplorerUrl
  // },
  // {
  //   chainId: 1313161554,
  //   chainKey: "aurora",
  //   name: "Aurora",
  //   rpcUrl: "https://mainnet.aurora.dev",
  //   currency: "ETH",
  //   nativeTokenReserve: 0.01,
  //   weth: "0xC9BdeEd33CD01541e1eeD10f90519d2C06Fe3feB",
  //   dexScreenerChainId: "aurora",
  //   dexToolsChainId: "aurora",
  //   image: "aurora.png",
  //   useDappChain: Aurora,
  //   explorerUrl: Aurora.blockExplorerUrl
  // },
  //   {
  //     chainId: 25,
  //     chainKey: "cronos",
  //     name: "Cronos",
  //     rpcUrl: "https://evm.cronos.org",
  //     currency: "CRO",
  //     nativeTokenReserve: 0.01,
  //     weth: "0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23",
  //     dexScreenerChainId: "cronos",
  //     dexToolsChainId: "cronos",
  //     image: "cronos.png",
  //     useDappChain: Cronos,
  //     explorerUrl: Cronos.blockExplorerUrl
  //   },
  // {
  //   chainId: 2222,
  //   chainKey: "kava",
  //   name: "Kava",
  //   rpcUrl: "https://evm.kava-rpc.com",
  //   currency: "KAVA",
  //   nativeTokenReserve: 0.01,
  //   weth: "0xc86c7C0eFbd6A49B35E8714C5f59D99De09A225b",
  //   dexScreenerChainId: "kava",
  //   dexToolsChainId: "kava",
  //   image: "kava.png",
  //   useDappChain: undefined,
  //   explorerUrl: "https://kavascan.com"
  // },
  // {
  //   chainId: 1088,
  //   chainKey: "metis",
  //   name: "Andromeda",
  //   rpcUrl: "https://andromeda.metis.io/?owner=1088",
  //   currency: "METIS",
  //   nativeTokenReserve: 0.01,
  //   weth: "0x75cb093E4D61d2A2e65D8e0BBb01DE8d89b53481",
  //   dexScreenerChainId: "metis",
  //   dexToolsChainId: "metis",
  //   image: "metis.png",
  //   useDappChain: Andromeda,
  //   explorerUrl: Andromeda.blockExplorerUrl
  // },
  //   {
  //     chainId: 42220,
  //     chainKey: "celo",
  //     name: "Celo",
  //     rpcUrl: "https://forno.celo.org",
  //     currency: "CELO",
  //     nativeTokenReserve: 0.01,
  //     weth: "0xE919F65739c26a42616b7b8eedC6b5524d1e3aC4",
  //     dexScreenerChainId: "celo",
  //     dexToolsChainId: "celo",
  //     image: "celo.png",
  //     useDappChain: Celo,
  //     explorerUrl: Celo.blockExplorerUrl
  //   },
  //   {
  //     chainId: 40,
  //     chainKey: "telos",
  //     name: "Telos",
  //     rpcUrl: "https://mainnet.telos.net/evm",
  //     currency: "TLOS",
  //     nativeTokenReserve: 0.01,
  //     weth: "0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E",
  //     dexScreenerChainId: "telos",
  //     dexToolsChainId: "telos",
  //     image: "telos.png",
  //     useDappChain: Telos,
  //     explorerUrl: "https://www.teloscan.io"
  //   },
  // {
  //   chainId: 1101,
  //   chainKey: "polygon_zkevm",
  //   name: "zkEVM",
  //   rpcUrl: "https://zkevm-rpc.com",
  //   currency: "ETH",
  //   nativeTokenReserve: 0.01,
  //   weth: "0x4F9A0e7FD2Bf6067db6994CF12E4495Df938E6e9",
  //   dexScreenerChainId: "polygonzkevm",
  //   dexToolsChainId: "polygonzkevm",
  //   image: "polygonzkevm.png",
  //   useDappChain: undefined,
  //   explorerUrl: "https://zkevm.polygonscan.com"
  // },
  // {
  //   chainId: 100,
  //   chainKey: "xdai",
  //   name: "Gnosis",
  //   rpcUrl: "https://rpc.gnosischain.com",
  //   currency: "XDAI",
  //   nativeTokenReserve: 0.01,
  //   weth: "0xe91D153E0b41518A2Ce8Dd3D7944Fa863463a97d",
  //   dexScreenerChainId: "gnosischain",
  //   dexToolsChainId: "gnosis",
  //   image: "gnosischain.png",
  //   useDappChain: Gnosis,
  //   explorerUrl: Gnosis.blockExplorerUrl
  // },
  // {
  //   chainId: 204,
  //   chainKey: "opbnb",
  //   name: "opBNB",
  //   rpcUrl: "https://opBNB-mainnet-rpc.bnbchain.org",
  //   currency: "BNB",
  //   nativeTokenReserve: 0.01,
  //   weth: "0x4200000000000000000000000000000000000006",
  //   dexScreenerChainId: "opbnb",
  //   dexToolsChainId: "opbnb",
  //   image: "opbnb.png",
  //   useDappChain: undefined,
  //   explorerUrl: "https://opbnbscan.com"
  // },
  // {
  //   chainId: 5000,
  //   chainKey: "mantle",
  //   name: "Mantle",
  //   rpcUrl: "https://rpc.mantle.xyz",
  //   currency: "MNT",
  //   nativeTokenReserve: 0.01,
  //   weth: "0x78c1b0C915c4FAA5FffA6CAbf0219DA63d7f4cb8",
  //   dexScreenerChainId: "mantle",
  //   dexToolsChainId: "mantle",
  //   image: "mantle.png",
  //   useDappChain: undefined,
  //   explorerUrl: "https://explorer.mantle.xyz"
  // },
  // {
  //   chainId: 169,
  //   chainKey: "manta",
  //   name: "Manta",
  //   rpcUrl: "https://pacific-rpc.manta.network/http",
  //   currency: "ETH",
  //   nativeTokenReserve: 0.01,
  //   weth: "0x0Dc808adcE2099A9F62AA87D9670745AbA741746",
  //   dexScreenerChainId: "manta",
  //   dexToolsChainId: "manta",
  //   image: "manta.png",
  //   useDappChain: undefined,
  //   explorerUrl: "https://pacific-explorer.manta.network"
  // },
  // {
  //   chainId: 534352,
  //   chainKey: "scroll",
  //   name: "Scroll",
  //   rpcUrl: "https://rpc.scroll.io",
  //   currency: "ETH",
  //   nativeTokenReserve: 0.01,
  //   weth: "0x5300000000000000000000000000000000000004",
  //   dexScreenerChainId: "scroll",
  //   dexToolsChainId: "scroll",
  //   image: "scroll.png",
  //   useDappChain: undefined,
  //   explorerUrl: "https://scrollscan.com"
  // },
  // {
  //   chainId: 81457,
  //   chainKey: "blast",
  //   name: "Blast",
  //   rpcUrl: "https://rpc.blast.io",
  //   currency: "ETH",
  //   nativeTokenReserve: 0.01,
  //   weth: "0x4300000000000000000000000000000000000004",
  //   dexScreenerChainId: "blast",
  //   dexToolsChainId: "blast",
  //   image: "blast.png",
  //   useDappChain: undefined,
  //   explorerUrl: "https://blastscan.io"
  // },
  // {
  //   chainId: 34443,
  //   chainKey: "mode",
  //   name: "Mode",
  //   rpcUrl: "https://mainnet.mode.network",
  //   currency: "ETH",
  //   nativeTokenReserve: 0.01,
  //   weth: "0x4200000000000000000000000000000000000006",
  //   dexScreenerChainId: "mode",
  //   dexToolsChainId: "mode",
  //   image: "mode.png",
  //   useDappChain: undefined,
  //   explorerUrl: "https://explorer.mode.network"
  // },
];

// if (TEST_CHAIN_ENABLED) {
//   let chain = chainList.find(chain => chain.chainId == TEST_FORKED_CHAIN_ID);
//   if (!!chain) {
//     chainList = [
//       {
//         ...chain,
//         name: `(Test) ${chain.name}`,
//         chainId: TEST_CHAIN_ID,
//         rpcUrl: TEST_CHAIN_RPC_URL,
//       },
//       ...chainList,
//     ];
//   }
//  }

export default chainList;
