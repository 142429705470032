import React from "react";
import { isNumberKey } from "../../utils/numberUtils";

const Input = React.forwardRef((props: any, ref) => {
  const { type = "text", className = "", onChange, ...rest } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (type === "number") {
      if (!isNumberKey(e.target.value.slice(-1))) {
        e.target.value = e.target.value.slice(0, -1);
      }
    }
    onChange && onChange(e);
  };

  return (
    <input
      ref={ref}
      type={type}
      className={`bg-transparent outline-none border-0 text-white w-full ${className}`}
      onChange={handleChange}
      {...rest}
    />
  );
});

export default Input;
