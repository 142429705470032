import React, { useContext } from 'react'
import { ChainConfigContext } from '../contexts/ChainConfigContext';
import { priorityTokenTickers } from '../config/priorityTokenTickers';

const defaultTokensFrom = ["USDT", "USDC"];
const defaultTokensTo = ["", "ETH"]; // empty is native token

export default function useDefaultTokens() {

    const { getTokenByTicker, supportedTokens } = useContext(ChainConfigContext);

    const getDefaultTokenFrom = (chainId:number, exclude:string|undefined = undefined) => {

      let tokens = [
        ...defaultTokensFrom, 
        ...supportedTokens?.[chainId]?.[0]?.symbol 
          ? [supportedTokens?.[chainId]?.[0]?.symbol]
          : []
      ];

      try {
        const usedTokens = JSON.parse(localStorage.getItem(`lastUsedTokens`) || "{}");
        if (usedTokens?.[chainId]?.from !== undefined) {
          tokens = [usedTokens?.[chainId]?.from, ...tokens];
        }
      } catch (err:any) {}

      tokens = [...new Set(tokens)]
        .filter(e => e !== exclude)
        .filter(e => !!getTokenByTicker(e, chainId));
      
      return getTokenByTicker(tokens[0], chainId) || undefined;
    }

    const getDefaultTokenTo = (chainId:number, exclude:string|undefined = undefined) => {

      let tokens = [
        ...defaultTokensTo, 
        ...supportedTokens?.[chainId]?.[0]?.symbol 
          ? [supportedTokens?.[chainId]?.[0]?.symbol]
          : []
      ];

      try {
        const usedTokens = JSON.parse(localStorage.getItem(`lastUsedTokens`) || "{}");
        if (usedTokens?.[chainId]?.to !== undefined) {
          tokens = [usedTokens?.[chainId]?.to, ...tokens];
        }
      } catch (err:any) {}

      tokens = [...new Set(tokens)]
        .filter(e => e !== exclude)
        .filter(e => !!getTokenByTicker(e, chainId));
      
      return getTokenByTicker(tokens[0], chainId) || undefined;
    }

    const saveDefaultTokens = (chainId:number, from:string|undefined, to:string|undefined) => {
      if (from !== undefined && to !== undefined) {
        let usedTokens = JSON.parse(localStorage.getItem(`lastUsedTokens`) || "{}");
        usedTokens[chainId] = { from, to };
        localStorage.setItem(`lastUsedTokens`, JSON.stringify(usedTokens));
      }
    }

    return {
      getDefaultTokenFrom,
      getDefaultTokenTo,
      saveDefaultTokens
    }
}
