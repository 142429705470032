import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { ethers } from 'ethers';
import { ChainConfigContext, IChain } from './ChainConfigContext';
import { ChainContext } from './ChainContext';
import useContract from '../hooks/useContract';
import { FormContext } from './FormContext';
import ERC20_ABI from "../config/abis/ERC20.json";
import { isNativeToken } from '../utils/tokenUtils';
import BLOCKED_TOKENS from '../config/blockedTokens';

const MULTICALL_ABI = [
  'function aggregate(tuple(address target, bytes callData)[] calls) view returns (uint256 blockNumber, bytes[] returnData)',
  'function getEthBalance(address addr) view returns (uint256 balance)'
];

interface TokenBalancesContextType {
  getBalance: (chainId: number, tokenAddress: string) => string;
  getLoading: (chainId: number) => boolean;
  refreshBalances: () => Promise<void>;
}

const TokenBalancesContext = createContext<TokenBalancesContextType>({
  getBalance: (chainId: number, tokenAddress: string) => '0',
  getLoading: (chainId: number) => false,
  refreshBalances: () => Promise.resolve(),
});

const CACHE_DURATION = 30 * 1000;
const CHUNK_SIZE = 50; 

const TokenBalancesProvider = ({ children }) => {
  const { form } = useContext(FormContext);
  const { supportedTokens, getChainById } = useContext(ChainConfigContext);
  const { connectedAccount } = useContext(ChainContext);
  const [balances, setBalances] = useState<{ [chainId: number]: { [tokenAddress: string]: string } }>({});
  const [loading, setLoading] = useState<{ [chainId: number]: boolean }>({});

  const chainFrom: IChain | undefined = getChainById(form.chainIdFrom);
  const chainTo: IChain | undefined = getChainById(form.chainIdTo);

  const multicallFrom = useContract(chainFrom?.useDappChain?.multicallAddress, MULTICALL_ABI, form.chainIdFrom);
  const multicallTo = useContract(chainTo?.useDappChain?.multicallAddress, MULTICALL_ABI, form.chainIdTo);

  const fetchBalancesForChain = useCallback(async (chainId: number, multicall: any | null | undefined) => {
    if (!connectedAccount || !multicall) return;

    const multicallAddress = await multicall.getAddress();
    const tokens = supportedTokens?.[chainId]?.filter(token => !(BLOCKED_TOKENS?.[chainId]?.map(t => t.toLowerCase()) || [])?.includes(token.address.toLowerCase())) || [];
    if (tokens.length === 0) return;

    setLoading(prev => ({ ...prev, [chainId]: true }));

    const erc20Interface = new ethers.Interface(ERC20_ABI); // TODO: verify if this is correct. Previous: new Interface(ERC20_ABI)
    const multicallInterface = new ethers.Interface(MULTICALL_ABI); // TODO: verify if this is correct. Previous: new Interface(MULTICALL_ABI)

    const newBalances: { [tokenAddress: string]: string } = {};

    for (let i = 0; i < tokens.length; i += CHUNK_SIZE) {
      const chunk = tokens.slice(i, i + CHUNK_SIZE);
      const calls = chunk.map(token => ({
        target: isNativeToken(token.address) 
          ? multicallAddress
          : token.address,
        callData: isNativeToken(token.address) 
          ? multicallInterface.encodeFunctionData('getEthBalance', [connectedAccount])
          : erc20Interface.encodeFunctionData('balanceOf', [connectedAccount])
      }));

      try {
        const { returnData } = await multicall.aggregate(calls);
        chunk.forEach((token, index) => {
          const balance = returnData[index] || 0; // TODO: verify if this is correct. Previous: BigNumber.from(returnData[index])
          newBalances[token.address] = ethers.formatUnits(balance, token.decimals);
        });
      } catch (error) {
        console.error(`Error fetching balances for chain ${chainId} (chunk ${i}-${i+CHUNK_SIZE}):`, error);
      }
    }

    setBalances(prev => ({ ...prev, [chainId]: newBalances }));
    setLoading(prev => ({ ...prev, [chainId]: false }));
  }, [connectedAccount, supportedTokens]);

  const refreshBalances = useCallback(async () => {
    if (chainFrom && multicallFrom) {
      await fetchBalancesForChain(chainFrom.chainId, multicallFrom);
    }
    if (chainTo && multicallTo && chainTo.chainId !== chainFrom?.chainId) {
      await fetchBalancesForChain(chainTo.chainId, multicallTo);
    }
  }, [
    chainFrom,
    chainTo,
    multicallFrom,
    multicallTo,
    fetchBalancesForChain
  ]);

  useEffect(() => {
    if (connectedAccount) {
      refreshBalances();
    } else {
      setBalances({});
      setLoading({});
    }
  }, [
    connectedAccount,
    refreshBalances
  ]);

  const getBalance = useCallback((chainId: number, tokenAddress: string): string => {
    return connectedAccount ? (balances?.[chainId]?.[tokenAddress] || '0') : '0';
  }, [balances, connectedAccount]);

  const getLoading = useCallback((chainId: number): boolean => {
    return connectedAccount ? ((loading?.[chainId] || false) && !balances?.[chainId]) : false;
  }, [loading, connectedAccount, balances]);

  return (
    <TokenBalancesContext.Provider value={{
      getBalance,
      getLoading,
      refreshBalances
    }}>
      {children}
    </TokenBalancesContext.Provider>
  );
};

export { TokenBalancesContext, TokenBalancesProvider };