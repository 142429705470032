import chainList from "./chainList";

// Enviornment
export const ENVIRONMENT = process.env.GATSBY_ENVIRONMENT;

// GraphQL API Endpoint
export const GRAPHQL_URL = process.env.GATSBY_GRAPHQL_URL;

// Tokens
export const NULL_ADDRESS = "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE";
export const NATIVE_ADDRESSES = [
  "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
  "0x0000000000000000000000000000000000000000",
  "0x0000000000000000000000000000000000001010"
]
export const STABLE_COINS = [
  "USDT",
  "USDC",
  "USDC.e",
  "DAI",
  "BUSD",
  "TUSD",
  "USDN",
  "GUSD",
  "PAX",
  "SUSD"
];
export const TOKEN_PRICES_UPDATE_INTERVAL = 20;

// Test Chain
export const TEST_CHAIN_ID = parseInt(
  (process.env.GATSBY_TEST_CHAIN_ID || "") as unknown as string,
);
export const TEST_FORKED_CHAIN_ID = parseInt(
  (process.env.GATSBY_TEST_FORKED_CHAIN_ID || "") as unknown as string,
);
export const TEST_CHAIN_RPC_URL = process.env.GATSBY_TEST_CHAIN_RPC_URL || "";
export const TEST_CHAIN_ENABLED = !!parseInt(
  process.env.GATSBY_TEST_CHAIN_ENABLED || "",
);

// TradingView
export const TRADING_VIEW_API_ENDPOINT =
  "https://symbol-search.tradingview.com/symbol_search";

// NFT
export const NFTS_CHAIN_ID = parseInt(process.env.GATSBY_NFTS_CHAIN_ID || "");
export const OLYMPIANS_DASHBOARD_ENABLED = !!parseInt(
  process.env.GATSBY_OLYMPIANS_DASHBOARD_ENABLED || "",
);
export const OLYMPEX_PASS_BASE_TOKEN_URI =
  process.env.GATSBY_OLYMPEX_PASS_BASE_TOKEN_URI;

// WalletConnect
export const WALLETCONNECT_PROJECT_ID =
  process.env.GATSBY_WALLETCONNECT_PROJECT_ID || "";
export const DEFAULT_CHAIN_ID = parseInt(
  process.env.GATSBY_DEFAULT_CHAIN_ID || chainList[0]?.chainId.toString(),
);

// Miscelaneous
export const GITBOOK_URL = "https://gitbook.com";
export const TWITTER_URL = "";
export const DISCORD_URL = "";
export const TELEGRAM_URL = "";
export const UTILITY_NFT_OPENSEA_LINK = "https://opensea.io";
export const OLYMPIANS_NFT_OPENSEA_LINK = "https://opensea.io";

export const PUBLIC_URL = process.env.GATSBY_PUBLIC_URL;

// UI
export const SCREEN_MAX_WIDTH = 1240;
export const SWAP_CARD_WIDTH = 398;

// Gas config
export const DEFAULT_GAS_PRICE_ID = 'normal';
export const DEFAULT_GAS_PRICE = 126;

// Security
export const AUTH_CODE = process.env.GATSBY_AUTH_CODE || "";

// Slippage
export const DEFAULT_SLIPPAGE = 1;
export const MIN_SLIPPAGE = 0.5;
export const DEFAULT_CROSS_CHAIN_SLIPPAGE = 5;

// Logs
export const MIXPANEL_TOKEN = process.env.GATSBY_MIXPANEL_TOKEN || "";