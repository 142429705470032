import { NATIVE_ADDRESSES, NULL_ADDRESS, STABLE_COINS } from "../config/constants";
import { priorityTokenTickers } from "../config/priorityTokenTickers";

export const sortTokenList = (tokens) => {
  tokens = tokens.slice()?.sort((a,b) => 
    priorityTokenTickers.indexOf(b.symbol.toUpperCase()) - 
    priorityTokenTickers.indexOf(a.symbol.toUpperCase())
  );
  tokens = tokens.slice()?.sort((a,b) => 
    NATIVE_ADDRESSES.indexOf(b.address) - 
    NATIVE_ADDRESSES.indexOf(a.address)
  );
  return tokens;
}

export const isNativeToken = (tokenAddress:string | undefined) => {
  if (tokenAddress === undefined) return false;
  return NATIVE_ADDRESSES.map(e => e.toLowerCase()).includes(tokenAddress.toLowerCase());
}

export const isStableCoin = (ticker:string | undefined) => {
  if (ticker === undefined) return false;
  return STABLE_COINS.map(e => e.toLowerCase()).includes(ticker.toLowerCase());
}

export const correctTokenAddress = (tokenAddress: string) => {
  if (isNativeToken(tokenAddress)) return NULL_ADDRESS;
  return tokenAddress;
}
