import {
  useDisconnect,
  useSwitchNetwork,
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
  useWeb3ModalEvents
} from "@web3modal/ethers/react";

export default function useWalletConnect() {
  const { open } = useWeb3Modal();
  const { disconnect } = useDisconnect();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const { switchNetwork } = useSwitchNetwork();
  const events = useWeb3ModalEvents();

  return {
    open,
    disconnect,
    switchNetwork,
    address,
    chainId,
    isConnected,
    walletProvider,
    events
  };
}
