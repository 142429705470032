import { gql } from "@apollo/client";

export const GET_TOKEN_PRICES = gql`
  query TokenPrices($addressOne: String!, $addressTwo: String!) {
    tokenPrices(params: { addressOne: $addressOne, addressTwo: $addressTwo }) {
      ratio
      tokenOne
      tokenTwo
    }
  }
`;

export const GET_QUOTE_SWAP = gql`
  mutation GetQuoteSwap($getQuoteSwapParams: QuoteSwapInput!) {
    getQuoteSwap(params: $getQuoteSwapParams) {
      code
      message
      quoteSwap {
        data
        estimatedGas
        minOutAmount
        outAmount
        value
      }
      success
    }
  }
`;

export const GET_TOKEN_LIST = gql`
  query GetTokenList($chainId: Int!) {
    getTokenList(chainId: $chainId) {
      address
      decimals
      icon
      name
      symbol
    }
  }
`;

export const GET_HISTORICAL_PRICE_BY_PAR = gql`
  query GetHistoricalPriceByPar($params: HistoricalPriceByParInput!) {
    getHistoricalPriceByPar(params: $params) {
      code
      message
      success
      prices
    }
  }
`;

export const GET_QUOTE = gql`
  query GetQuote($getQuoteParams: QuoteInput!) {
    getQuote(params: $getQuoteParams) {
      outAmount
      estimatedGas
      aggregatorId
      market {
        dexName
        swapAmount
        dexImageURL
      }
      routes {
        percentage
        subRoutes {
          from
          to
          dexes {
            name
            percentage
          }
        }
      }
    }
  }
`;

export const GET_NFT_SIGNATURE = gql`
  query GetSignature($account: String!) {
    mintSignature(account: $account)
  }
`;

export const GET_COINGECKO_PRICE_BY_TOKENADDRESS = gql`
  query CoingeckoPriceByTokenAddress(
    $coingeckoChainId: String!
    $tokenAddress: String!
    $currency: String!
  ) {
    coingeckoPriceByTokenAddress(
      coingeckoChainId: $coingeckoChainId
      tokenAddress: $tokenAddress
      currency: $currency
    )
  }
`;

export const GET_CROSS_CHAIN_LIST = gql`
  query GetCrossChainList {
    getCrossChainList {
      chains {
        chainId
      }
    }
  }
`;

export const GET_QUOTE_CROSS_CHAIN = gql`
  query GetCrossChainSwapQuery($params: CrossChainQuoteInput!) {
    getCrossChainQuote(params: $params) {
      code
      message
      success
      crossChainQuote {
        aggregatorId
        estimatedGas
        estimatedTime
        estimateCostInUSD
        fromTokenAmount
        toTokenAmount
        minimumReceived
        bridgeInfo {
          icon
          displayName
        }
        middlewareRoute {
          chainFrom {
            fromAsset {
              address
              decimals
              symbol
            }
            toAsset {
              address
              decimals
              symbol
            }
          }
          chainTo {
            fromAsset {
              address
              decimals
              symbol
            }
            toAsset {
              address
              decimals
              symbol
            }
          }
        }
      }
    }
  }
`;

export const GET_QUOTE_CROSS_CHAIN_SWAP = gql`
  mutation GetCrossChainSwap($params: CrossChainSwapInput!) {
    getCrossChainSwap(params: $params) {
      code
      message
      success
      crossChainSwap {
        calldata
        value
      }
    }
  }
`;

export const ASSOCIATE_REFERRAL = gql`
  mutation AssociateReferralMutation($referred: String!, $referrer: String!) {
    associateReferral(referred: $referred, referrer: $referrer) {
      code
      message
      success
    }
  }
`;

export const CREATE_NICKNAME = gql`
  mutation CreateNicknameMutation($nickname: String!, $address: String!) {
    createNickname(nickname: $nickname, address: $address) {
      code
      success
      message
    }
  }
`;

export const GET_NICKNAME_BY_ADDRESS = gql`
  query GetNicknameByAddress($getNicknameByAddressAddress2: String!) {
    getNicknameByAddress(address: $getNicknameByAddressAddress2)
  }
`;

export const GET_ADDRESS_BY_NICKNAME = gql`
  query GetAddressByNickname($getAddressByNicknameNickname2: String!) {
    getAddressByNickname(nickname: $getAddressByNicknameNickname2)
  }
`;

export const GET_DATA_DASHBOARD = gql`
  query GetDataDashboard($address: String!) {
    getDataDashboard(address: $address) {
      hash
      chainFrom
      chainTo
      tradedVolume
      tokenId
      timestamp
      srcToken
      spentAmount
      returnAmount
      nftPoints
      dstToken
      dstReceiver
    }
  }
`;

export const GET_REFERRALS_BY_ADDRESS = gql`
  query GetReferralsByAddress($address: String!) {
    getReferralsByAddress(address: $address)
  }
`;

export const GENERATE_METADATA_NFTS = gql`
  mutation GenerateMetadataNFT($account: String!, $tokenId: Int!) {
    generateMetadataNFT(account: $account, tokenId: $tokenId) {
      code
      message
      metadataURL
      success
    }
  }
`;

export const GET_TOKEN_IDS_BY_ADDRESS = gql`
  query GetTokenIdsByAddress($address: String!) {
    getTokenIdsByAddress(address: $address)
  }
`;

export const AUTHENTICATE = gql`
  mutation Authenticate($auth: AuthenticateInput!) {
    authenticate(auth: $auth) {
      token
    }
  }
`;

export const CREATE_LIMIT_ORDER = gql`
  mutation CreateLimitOrder($order: CreateLimitOrderInput!) {
    createLimitOrder(order: $order) {
      success
      message
      code
      limitOrder {
        id
        accountTo
        chainId
        gasPrice
        inTokenAddress
        outTokenAddress
        tokenASymbol
        tokenBSymbol
        slippage
        pair
        amount
        price
        priceTrigger
        status
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;

export const GET_LIMIT_ORDERS = gql`
  query GetLimitOrders {
    getLimitOrders {
      code
      message
      success
      limitOrders {
        id
        accountTo
        chainId
        gasPrice
        inTokenAddress
        outTokenAddress
        slippage
        pair
        tokenASymbol
        tokenBSymbol
        provider
        isUsdBase
        isReversedPair
        amount
        price
        priceTrigger
        expired
        reasonFail
        status
        txHash
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;

export const UPDATE_LIMIT_ORDER = gql`
  mutation UpdateLimitOrder($order: UpdateLimitOrderInput!) {
    updateLimitOrder(order: $order) {
      success
      message
    }
  }
`;

export const GET_SAVED_FEES_BY_ADDRESS = gql`
  query GetSavedFeesByAddress($address: String!) {
    getSavedFeesByAddress(address: $address)
  }
`;

export const GET_ALL_TOKENS = gql`
  query GetAllTokens {
    getAllTokens {
      chains {
        chainId
        tokens {
          address
          decimals
          icon
          name
          symbol
          usd
        }
      }
    }
  }
`

export const GET_CROSS_CHAIN_TX_STATUS = gql`
  query GetCrossChainTxStatus($hash: String!, $chainId: String!) {
    getCrossChainTxStatus(txHash: $hash, chainId: $chainId) {
      message
      txStatus { 
        fromChainId
        toChainId
        fromTxHash
        toTxHash
        fromAmount
        fromTokenAddress
        toAmount
        toTokenAddress
        errorMsg
        bridgeHash
        detailStatus
        status
        memo
      }
    }
  }
`