import React, { useContext, useEffect, useRef, useState } from "react";
import { ethers } from "ethers";
import { ChainContext } from "../contexts/ChainContext";
import { getProvider } from "../utils/getProvider";

export interface IGasPriceOption {
  id: string;
  label: string;
  gwei: undefined | number;
  multiplier: number;
}

export const gasPrices = [
  { 
    id: "slow", 
    label: "Slow", 
    gwei: undefined, 
    multiplier: 1.5,
  },
  { 
    id: "normal", 
    label: "Normal", 
    gwei: undefined, 
    multiplier: 2,
  },
  { 
    id: "fast", 
    label: "Fast", 
    gwei: undefined, 
    multiplier: 2.5,
  },
];

export default function useChainGasPrice() {

  const { selectedChainId } = useContext(ChainContext);
  const [ gasPriceOptions, setGasPriceOptions ] = useState<IGasPriceOption[]>(gasPrices);
  const [ maxFeePerGas, setMaxFeePerGas ] = useState<bigint | undefined>(undefined);
  const [ maxPriorityFeePerGas, setMaxPriorityFeePerGas ] = useState<bigint | undefined>(undefined);

  const updateGasPriceOptions = async () => {
    try {
      const provider = getProvider(selectedChainId);
      
      const gasPriceBigInt = (await provider.getFeeData()).gasPrice;
      const gasPriceGwei = parseFloat(ethers.formatUnits(gasPriceBigInt || ``, "gwei"));
      const newGasOptions = gasPriceOptions.map((e) => ({
        ...e,
        gwei: parseInt((gasPriceGwei * e.multiplier).toString()),
      }));
      setGasPriceOptions(newGasOptions);
    } catch (error) {
      console.error("Error fetching gas prices:", error);
    }
  };

  const updateMaxFeePerGas = async () => {
    const provider = getProvider(selectedChainId);
    const feeData = await provider.getFeeData();
    if (feeData.maxFeePerGas) {
      setMaxFeePerGas(feeData.maxFeePerGas);
    }
    if (feeData.maxPriorityFeePerGas) {
      setMaxPriorityFeePerGas(feeData.maxPriorityFeePerGas);
    }
  };

  useEffect(() => {
    updateGasPriceOptions();
    updateMaxFeePerGas();
    setTimeout(() => {
      updateGasPriceOptions();
      updateMaxFeePerGas();
    }, 10000);
  }, [selectedChainId]);

  return { gasPriceOptions, maxFeePerGas, maxPriorityFeePerGas };
}