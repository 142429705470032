import React, { createContext, useContext, useEffect, useState } from "react";
import { ENVIRONMENT, MIXPANEL_TOKEN } from "../config/constants";
import { ChainContext } from "./ChainContext";

const LogContext = createContext({
  track: (event: string, properties: any) => {},
});

export const LogProvider = ({ children }) => {

  const { connectedAccount } = useContext(ChainContext);

  const track = (event: string, properties: any) => {
    properties = {
      ...(properties || {}),
      environment: ENVIRONMENT,
    };
    try {
      if (typeof window !== "undefined") {
        (window as any)?.mixpanel?.main?.track(event, properties);
      }
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      try {
        (window as any)?.mixpanel.init(MIXPANEL_TOKEN, {
          debug: true,
          track_pageview: false,
          persistence: "localStorage",
        }, 'main');
      } catch (e) {
        console.error(e);
      }
    }
  }, []);

  useEffect(() => {
    if (connectedAccount) {
      if (typeof window !== "undefined") {
        (window as any)?.mixpanel?.main?.identify(connectedAccount);
      }
    }
  }, [connectedAccount]);

  return (
    <LogContext.Provider value={{ 
      track
    }}>
      {children}
    </LogContext.Provider>
  );
};

export const useLog = () => useContext(LogContext);
