import BigNumber from "bignumber.js";

export const formatNumber = (
  number,
  decimals = 0,
  minDecimals: number | undefined = undefined,
  useGrouping: boolean = true
) => {
  number = Number(number).toLocaleString(undefined, {
    maximumFractionDigits: decimals,
    minimumFractionDigits: minDecimals,
    useGrouping: useGrouping
  });
  return number;
};

export const formatBigNumber = (
  bn: BigNumber | undefined,
  tokenDecimals = 18,
  decimals = 4,
) => {
  bn = new BigNumber(bn || 0);
  bn = bn.dividedBy(new BigNumber(10).pow(tokenDecimals));
  return bn.toFixed(decimals);
};

export const truncateDecimals = function (number: number, maxDigits: number) {
  if (!number) return number;
  const numberString = number.toString();
  const parts = numberString.split(/[.,]/); // Split by either '.' or ','
  if (parts.length === 1) return number; // No decimal part
  if (parts[1].length <= maxDigits) return number; // Already within max digits
  const decimalSeparator = numberString.includes('.') ? '.' : ',';
  return parseFloat(parts[0] + decimalSeparator + parts[1].slice(0, maxDigits));
};

export const isNumberKey = (char: string) => {
   if (char === "," || char === "." || (parseInt(char) >= 0 && parseInt(char) <= 9))
      return true;
   return false;
}